var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1d9d264be37ad5d033a282b92fcd1a0b13d8cafd"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { isProductionEnv } from '@netpurpose/utils'
import { sentryConfig } from './sentryShared.config'

Sentry.init({
  ...sentryConfig,
  replaysSessionSampleRate: isProductionEnv() ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
})
